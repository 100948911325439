// ChatInterface.js
import React, { useRef, useState } from "react";
import {
  View,
  Text,
  StyleSheet,
  Animated,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Platform,
  ScrollView,
} from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { ActivityIndicator } from "react-native";
import { supabase } from "../../backend/supabase";
import {
    FunctionsHttpError,
    FunctionsRelayError,
    FunctionsFetchError,
  } from '@supabase/supabase-js';

const SCREEN_WIDTH = Dimensions.get("window").width;

const SmartsComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const animation = useRef(new Animated.Value(0)).current;
  const [messages, setMessages] = useState([]); // State to hold messages
  const [inputText, setInputText] = useState(""); // State for input text
  const scrollViewRef = useRef();

  const toggleChat = () => {
    Animated.timing(animation, {
      toValue: isOpen ? 0 : 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
    setIsOpen(!isOpen);
  };

  // Function to handle sending messages
  const handleSend = async () => {
    if (inputText.trim().length > 0) {
      // Add user's message
      const userMessage = {
        id: Date.now(),
        text: inputText,
        sender: 'user',
        timestamp: new Date(),
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setInputText('');

      // Add thinking message
      const thinkingMessage = {
        id: Date.now() + 1,
        text: 'SMARTS is thinking...',
        sender: 'SMARTS',
        timestamp: new Date(),
        isThinking: true,
      };
      setMessages(prevMessages => [...prevMessages, thinkingMessage]);

      try {
        // Invoke the Supabase Edge Function
        const { data, error } = await supabase.functions.invoke('actions_core_execute', {
          body: {
            actionName: 'openai_completion',
            input: {
              model: 'gpt-4o',
              prompt: inputText,
            },
          }
        });

        // Remove the thinking message
        setMessages(prevMessages => prevMessages.filter(msg => !msg.isThinking));

        if (error) {
          // Handle different types of errors
          if (error instanceof FunctionsHttpError) {
            const errorMessage = await error.context.text();
            console.error('Function returned an error:', errorMessage);
            addErrorMessage('Function error: ' + errorMessage);
          } else if (error instanceof FunctionsRelayError) {
            console.error('Relay error:', error.message);
            addErrorMessage('Relay error: ' + error.message);
          } else if (error instanceof FunctionsFetchError) {
            console.error('Fetch error:', error.message);
            addErrorMessage('Fetch error: ' + error.message);
          } else {
            console.error('Unknown error:', error.message);
            addErrorMessage('An unknown error occurred.');
          }
        } else {
            console.log("Response return",data);
          // Add the response message
          const smartsMessage = {
            id: Date.now() + 2,
            text: data.data.choices[0].message.content, // Adjust based on your function's response structure
            sender: 'SMARTS',
            timestamp: new Date(),
          };
          setMessages(prevMessages => [...prevMessages, smartsMessage]);
        }
      } catch (err) {
        // Remove the thinking message
        setMessages(prevMessages => prevMessages.filter(msg => !msg.isThinking));
        console.error('Unexpected error:', err);
        addErrorMessage('An unexpected error occurred. Please try again later.');
      }
    }
  };

  // Helper function to add an error message
  const addErrorMessage = (text) => {
    const errorMessage = {
      id: Date.now() + 3,
      text: text,
      sender: 'SMARTS',
      timestamp: new Date(),
    };
    setMessages(prevMessages => [...prevMessages, errorMessage]);
  };
  // Interpolate the animation value to get the panel's translateX
  const translateX = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -350],
  });
  const translateX2 = animation.interpolate({
    inputRange: [0, 1],
    outputRange: [-20, -370],
  });

  return (
    <View style={styles.container}>
      {/* SMARTS Tag Button */}
      <Animated.View
        style={[
          styles.tagButtonContainer,
          {
            transform: [{ translateX: translateX2 }],
          },
        ]}
      >
        <TouchableOpacity style={styles.tagButton} onPress={toggleChat}>
          <Text style={styles.tagText}>SMARTS</Text>
        </TouchableOpacity>
      </Animated.View>

      {/* Sliding Chat Panel */}
      <Animated.View
        style={[
          styles.chatPanel,
          {
            transform: [{ translateX: translateX }],
          },
        ]}
      >
        {/* Chat Messages Area */}
        <ScrollView
          style={styles.messagesContainer}
          ref={scrollViewRef}
          onContentSizeChange={() => scrollViewRef.current.scrollToEnd({ animated: true })}
        >
          {messages.map((message) => (
            <View
              key={message.id}
              style={[styles.messageBubble, message.sender === "user" ? styles.userBubble : styles.smartsBubble]}
            >
              {message.sender === "SMARTS" ? (
                <View style={styles.smartsMessageContent}>
                  <MaterialIcons name="lightbulb" size={16} color="#FFF" style={{ marginRight: 5 }} />
                  {message.isThinking ? (
                    // Display ActivityIndicator when thinking
                    <ActivityIndicator size="small" color="#FFA500" />
                  ) : (
                    <Text style={styles.messageText}>{message.text}</Text>
                  )}
                </View>
              ) : (
                <Text style={styles.messageText}>{message.text}</Text>
              )}
              <Text style={styles.timestampText}>
                {message.timestamp.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}
              </Text>
            </View>
          ))}
        </ScrollView>

        {/* Input Box with Send Icon */}
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.textInput}
            placeholder="Type a message..."
            value={inputText}
            onChangeText={(text) => setInputText(text)}
          />
          <TouchableOpacity onPress={handleSend}>
            <MaterialIcons name="send" size={24} color="#007AFF" />
          </TouchableOpacity>
        </View>
      </Animated.View>
    </View>
  );
};

export default SmartsComponent;

const styles = StyleSheet.create({
  container: {
    position: Platform.OS === "web" ? "fixed" : "absolute",
    top: 0,
    right: -350,
    zIndex: 1000,
    width: 400,
    height: "100%",
    pointerEvents: "box-none",
  },
  tagButtonContainer: {
    position: "absolute",
    bottom: 150,
    left: 30,
    backgroundColor: "#007AFF",
    width: 40,
    height: 100,
    justifyContent: "center",
    alignItems: "center",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  tagButton: {
    transform: [{ rotate: "270deg" }],
    zIndex: 1001,
  },
  tagText: {
    color: "#fff",
    fontWeight: "bold",
  },
  chatPanel: {
    position: "absolute",
    top: 0,
    bottom: 0,
    width: 350,
    backgroundColor: "#444",
    borderLeftWidth: 1,
    borderColor: "#777",
    right: 0,
    zIndex: 1000,
  },
  messagesContainer: {
    flex: 1,
    padding: 10,
  },
  messageBubble: {
    maxWidth: "70%",
    marginVertical: 5,
    padding: 10,
    borderRadius: 10,
  },
  userBubble: {
    alignSelf: "flex-end",
    backgroundColor: "#555",
    borderTopRightRadius: 0,
  },
  smartsBubble: {
    alignSelf: "flex-start",
    backgroundColor: "#333",
    borderTopLeftRadius: 0,
    borderWidth: 1,
    borderColor: "#222",
    width: "80%",
  },
  smartsMessageContent: {
    flexDirection: "row",
    alignItems: "center",
  },
  messageText: {
    fontSize: 14,
    color: "#FFFFFF",
  },
  timestampText: {
    fontSize: 10,
    color: "#999",
    alignSelf: "flex-end",
    marginTop: 5,
  },
  inputContainer: {
    flexDirection: "row",
    padding: 10,
    borderTopWidth: 1,
    borderColor: "#F4F4F4",
    alignItems: "center",
  },
  textInput: {
    flex: 1,
    height: 40,
    paddingHorizontal: 10,
    backgroundColor: "#F0F0F0",
    borderRadius: 20,
    marginRight: 10,
  },
});
