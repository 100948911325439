// Navigation
import React, { useState, useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createDrawerNavigator } from '@react-navigation/drawer';
import navigationConfig from "./config/navigationConfig.json";
import { View, Text } from "react-native";
import { supabase } from "./backend/supabase";

import DynamicViewContainer from "./components/views/DynamicViewContainer";

import Auth from "./backend/Auth";
import HomePage from "./screens/HomePage";
import CustomDrawerContent from "./components/menus/CustomDrawerContent";
import SmartsComponent from "./components/smarts/Smarts";

const componentMapping = {
  HomePage,
  DynamicViewContainer,
  // ...other components
};


const Drawer = createDrawerNavigator();
const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

// Placeholder components for layouts and screens
// Define layouts using DynamicViewContainer
const Layouts = {
    PublicLayout: ({ children }) => (
      <DynamicViewContainer
        viewConfigFile="layoutViews.json"
        viewName="PublicLayout"
        extraProps={{ children }}
      />
    ),
    ProtectedLayout: ({ children }) => (
      <DynamicViewContainer
        viewConfigFile="layoutViews.json"
        viewName="ProtectedLayout"
        extraProps={{ children }}
      />
    ),
  };

//   const CustomDrawerContent = (props) => {
//     return (
//       <DrawerContentScrollView {...props}>
//         {/* Render your menu here */}
//         <DynamicViewContainer
//             viewConfigFile="layoutViews.json"
//             viewName="Menu"
//             extraProps={props}
            
//         />
//       </DrawerContentScrollView>
//     );
//   };

const DefaultScreen = ({ route }) => (
  <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
    <Text>{route.name} Screen</Text>
  </View>
);

export default function AppNavigator() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const { routes } = navigationConfig;

  // Separate routes
  const publicRoutes = routes.filter((route) => route.public);
  const protectedRoutes = routes.filter((route) => route.protected);

  // Function to generate screens
  const generateScreens = (routesArray, ScreenComponentType, isProtected = false) => {
    return routesArray.map((route, index) => {
      const ScreenComponent = (props) => {
        // Use the specified layout
        const Layout = Layouts[route.layout] || Layouts.PublicLayout;
        const UseComponent = componentMapping[route.component] || DefaultScreen;

        return (
          <Layout>
            <UseComponent {...props} {...(route.params || {})} />
            
          </Layout>
        );
      };

      return (
        <ScreenComponentType
          key={index}
          name={route.name}
          component={ScreenComponent}
          options={{ title: route.name, headerShown: false }}
        />
      );
    });
  };

  // Public Stack Navigator
  const PublicStack = () => (
    <Stack.Navigator>
      {generateScreens(publicRoutes, Stack.Screen)}
      <Stack.Screen key={"Auth"} name={"Auth"} component={Auth} options={{ title: "Login" }} />
    </Stack.Navigator>
  );

  // Protected Tab Navigator
  const ProtectedTabs = () => (
    <Drawer.Navigator drawerContent={(props) => <CustomDrawerContent {...props} />}>
      {generateScreens(protectedRoutes, Tab.Screen, true)}
      </Drawer.Navigator>
  );

  return (
    <NavigationContainer>
      {!session || !session.user ? <PublicStack /> : <ProtectedTabs />}
    </NavigationContainer>
  );
}
