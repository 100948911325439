// DynamicViewContainer.js
import React, { useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import ViewBuilder from './ViewBuilder';
import { Loading } from '../loadings';

import { supabase } from '../../backend/supabase';
import viewConfigRegistry from '../../config/viewConfigRegistry';
//import EnhancedViewBuilder from './EnhancedViewBuilder';

const DynamicViewContainer = ({ viewConfigFile, viewName, extraProps = {} }) => {
    const [viewConfig, setViewConfig] = useState(null);
    const navigation = useNavigation();
    const route = useRoute();
    const [isReady, setIsReady] = useState(false);
  
    useEffect(() => {
      const loadConfig = async () => {
        try {
          const configModule = viewConfigRegistry[viewConfigFile];
          console.log("CONFIG MODULE", {configModule,viewConfigFile});
          const config = configModule.default || configModule;
          const selectedView = config[viewName];
          if (!selectedView) {
            throw new Error(`View "${viewName}" not found in ${viewConfigFile}`);
          }
          setViewConfig(selectedView);
          setIsReady(true);
        } catch (error) {
          console.error('Error loading view configuration:', error);
        }
      };
  
      loadConfig();
    }, [viewConfigFile, viewName]);

  if (!isReady) {
    // Optionally render a loading indicator
    return <Loading />;
  }

  // Define any custom functions for event handlers
  const functionsMap = {
    handleLogout: () => {
        supabase.auth.signOut({scope:'local'});
      },
    handleFormSubmit: () => {
      // Implement your form submission logic
      console.log('Form submitted!');
    },
    toggleMenu: ()=> navigation.toggleDrawer(),
  };

  return ViewBuilder(viewConfig, navigation, route, functionsMap, extraProps);
};

export default DynamicViewContainer;
