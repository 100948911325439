// App.js
import React from "react";
import { Provider } from "mobx-react";
import 'react-native-get-random-values';
import { store } from "./stores";
import AppNavigator from "./navigator";



export default function App() {
  

  return (
    <Provider {...store}>
      <AppNavigator />
      
    </Provider>
  );
}
