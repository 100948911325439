import Logo from "./branding/logo";
import ActionButton from "./buttons/ActionButton";
import DynamicDataWrapper from "./data/DynamicDataWrapper";
import DynamicForm from "./forms/DynamicForm";
import TextField from "./inputs/textField";
import Menu from "./menus/verticalMenu";
import SmartsComponent from "./smarts/Smarts";
import DataTable from "./tables/DataTable"

const componentRegistry = {
    Logo,
    TextField,
    Menu,
    DataTable,
    ActionButton,
    DynamicForm,
    DynamicDataWrapper,
    SmartsComponent
    // ... map other components
  };
  
  export default componentRegistry;